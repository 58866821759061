<template>
  <b-nav-item-dropdown
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user d-flex align-content-start flex-wrap"
  >
    <template  #button-content>
      <!-- <div  -->
      <b-avatar
        size="40"
              :src="logo"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
    >
    <b-avatar
        size="40"
              :src="logo"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
      <span class="mx-1" >{{ userFirstName || userLastName }}</span>
    </b-dropdown-item>
   

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Deconnection</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  computed: {
    ...mapGetters('app', [
      'currentBreakPoint',
      'subDomain',
      'domain',
      'userId',
      'userFirstName',
      'userLastName',
      'userEmail'
    ])
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      // // Remove userData from localStorage
      // // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // // Reset ability
      // this.$ability.update(initialAbility)

      // // Redirect to login page
      // this.$router.push({ name: 'auth-login' })
      console.log('Deconnection');
      
      this.$emit('Deconnection');
    },
  },
}
</script>
